import React, { useEffect, useState } from "react"
import { useLocation } from "react-router-dom";
import { useTableStore, ColumnState, useUserStore } from "state";
import { generateTableConfig } from "utils";
import { TableNames } from "customEnums/TableNames";
import { Container } from "@mui/material";
import { CustomTable } from "common";
import { extractPageName } from "common/CustomTable/helpers";
import { useSetConfig } from "hooks";

export const IncomingResponseTable: React.FC<any> = ({ name, tableData, totalRecords }) => {
    const locationObj = useLocation()
    const pageName = extractPageName(locationObj.pathname)
    const tableConfig = useTableStore(state => state.tableConfig)
    const { tableHeaderCells, page, rowsPerPage } = useSetConfig(pageName, { page: 0, rowsPerPage: 5 }, TableNames.INCOMING_RESPONSE)

    const pagination = useTableStore(state => state.pagination)
    const setPagination = useTableStore(state => state.setPagination)

    useEffect(() => {
        setPagination({ ...pagination, [TableNames.INCOMING_RESPONSE]: { page: 0, rowsPerPage: 5 } })
    }, [])

    return (
        <>
            {tableHeaderCells &&

                <CustomTable
                    tableConfig={tableConfig}
                    tableName={name}
                    pageSize={rowsPerPage}
                    totalRecords={totalRecords}
                    rows={tableData}
                    orderBy="date"
                    order="desc"
                    hideSearch={true}
                    currentPage={page}
                    savedSearchState={[{}, () => { }]}
                />
            }
        </>
    )
}