import { useState, useEffect } from 'react';
import { urls } from '../urls'
import dayjs from 'dayjs';
import { v4 as uuid } from 'uuid'
import { useCustomModalStore, useTableStore } from 'state';

export const useEmailActivity = (regId: string) => {
    const reportingURL= urls.REPORTING;
    const controller = new AbortController();
    const [emailActivity, setEmailActivity] = useState<any>([]);
    const [emailCount, setEmailCount] = useState<number>(0);
    const [loading, setLoading] = useState(false);
    const setDisplayAlert = useTableStore(state => state.setDisplayAlert);
    const setSeverity = useTableStore((state) => state.setSeverity)
    const setAlertTitle = useTableStore(state => state.setAlertTitle);
    const setAlertMessage = useTableStore(state => state.setAlertMessage);
    const setOpenCustomModal = useCustomModalStore(state => state.setOpenCustomModal)

    const adjudicationUrl = `${process.env.NODE_ENV === 'production' ? reportingURL : ""}/adjudication`
    
    const resendEmail = async (id, email) => {
        let resp;
         let body = {
             "listOfApplicants": [
                 {
                     "regId": id,
                     "emailAddress": email
                 }
             ]
         }
         setOpenCustomModal(false);
         try {
             resp = await (await fetch(`${adjudicationUrl}/resubmit-applicant-pdfs`, {
                 method: 'POST',
                 credentials: "include",
                 body: JSON.stringify(body),
             }))
 
             if (resp.ok ){
                 setDisplayAlert(true)
                 setSeverity("success");
                 setAlertTitle("Email sent.");
                 setAlertMessage("Your email has been sent. You can review the delivery status in the Secure Email Activity Log.");
             } else {
                 setDisplayAlert(true)
                 setSeverity("error")
                 setAlertTitle("Error");
                 const respJSON = await resp.json();
                 setAlertMessage(respJSON.message);
             }
         } catch (err) {
             console.error("error fetching applicant's documents:", err);
         }
         return resp;
     }

    useEffect(() => {
        const fetchEmailActivity = async () => {
            const { signal } = controller;
            setLoading(true);
    
            try {
                const listOfEmailActivity = await (await fetch(`${adjudicationUrl}/secure-doc-table/${regId}?order=descending&page=1&count=100`, {
                    method: 'GET',
                    credentials: "include",
                    signal
                })).json();
    
                
                const emailActivityCount = await (await fetch(`${adjudicationUrl}/secure-doc-table/count/${regId}`, {
                    method: 'GET',
                    credentials: "include",
                    signal
                })).json();
                
                const transformData = listOfEmailActivity.secureDoc.map((data) => {
                    const parsedSubmitDate = JSON.parse(data.resubmitDate)
                    const parsedExpiredDate = JSON.parse(data.expireDate)
    
                    return {
                        key: uuid(),
                        id: data.regId,
                        recipient: data.recipientName,
                        recipientEmail: data.emailAddress,
                        date: dayjs(parsedSubmitDate).format("MM/DD/YYYY hh:mm:ss A"),
                        deliveryStatus: data.latestStatus,
                        resend: "resend",
                        expiredDate: dayjs(parsedExpiredDate),
                        resubmitCount: data.resubmitCount
                    }
                })
    
                console.log('trasnformedData', transformData);
                setEmailActivity(transformData)
                setEmailCount(emailActivityCount.count);
                setLoading(false);
    
    
            } catch (err) {
                console.error("error fetching applicant's documents:", err);
            }
        }

        fetchEmailActivity();

        return () => controller.abort()
    }, [regId, reportingURL])

    return { emailActivity, emailCount, resendEmail, loading }
}

