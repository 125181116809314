import { TableNames } from "customEnums/TableNames";
import { Container } from "@mui/material";
import { CustomTable } from "common";
import { extractPageName } from "common/CustomTable/helpers";
import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useTableStore } from "state";
import { useSetConfig } from "hooks";

export const StatusHistory: React.FC<any> = ({ statusHistories, totalRecords, name }) => {
    const locationObj = useLocation()
    const pageName = extractPageName(locationObj.pathname)
    const tableConfig = useTableStore(state => state.tableConfig)
    const pagination = useTableStore(state => state.pagination)
    const setPagination = useTableStore(state => state.setPagination)

    const { tableHeaderCells, page, rowsPerPage } = useSetConfig(pageName, { page: 0, rowsPerPage: 10 }, TableNames.STATUS_HISTORY)

    useEffect(() => {
        setPagination({ ...pagination, [TableNames.STATUS_HISTORY]: { page: 0, rowsPerPage: 10 } })
    }, [])

    return (
        <Container disableGutters maxWidth={false} sx={{ marginTop: "24px", marginBottom: "24px", width: "100%" }}>
            {
                tableHeaderCells &&
                <CustomTable
                    tableConfig={tableConfig}
                    tableName={name}
                    savedSearchState={[{}, () => { }]}
                    pageSize={rowsPerPage}
                    totalRecords={totalRecords}
                    currentPage={page}
                    hideSearch={true}
                    orderBy="statusDate"
                    order="desc"
                    defaultSortColumn="statusDate"
                    rows={statusHistories}
                />
            }

        </Container>
    )
}