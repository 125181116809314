import { TableNames } from "customEnums/TableNames";
import { Container } from "@mui/material";
import { CustomTable } from "common";
import { extractPageName } from "common/CustomTable/helpers";
import React, { ReactNode, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useRapbackPdfURL, useSetConfig } from "hooks";
import PrintIcon from '@mui/icons-material/Print';
import printJS from "print-js";
import { useTableStore } from "state";
import { useAudit } from "hooks/useAudit";
import OpenInNewIcon from '@mui/icons-material/OpenInNew';


export const AdjudicationDocs: React.FC<any> = ({ name, totalRecords, adjudicationDocuments }) => {
    const locationObj = useLocation()
    const pageName = extractPageName(locationObj.pathname)
    const tableConfig = useTableStore(state => state.tableConfig)
    const currentRow = useTableStore((state) => state.currentRow)
    const pagination = useTableStore(state => state.pagination)
    const setPagination = useTableStore(state => state.setPagination)

    console.log('adjudicationDocuments', adjudicationDocuments)

    const { addAuditEvent } = useAudit();
    const { rapbackPdfURL } = useRapbackPdfURL(currentRow as string, true);

    const printDoc = async (e, row) => {
        const data = await fetch(row.fileLink).then((res) => res.blob())
        const uri = URL.createObjectURL(data);
        //console.log('pdf data ', data)
        addAuditEvent("Printed", "Details / Adjudication Document", new Date(), row.registrationId)
        printJS(uri);
    }

    const saveDoc = async (e, row) => {
        const link = document.createElement("a");
        link.download = row.fileNameControl;
        const data = await fetch(row.fileLink).then((res) => res.blob())
        link.href = window.URL.createObjectURL(
            new Blob([data], { type: 'application/pdf' })
        );
        link.click();
        link.remove();
        addAuditEvent("Downloaded", "Details / Adjudication Document", new Date(), row.registrationId)
        window.URL.revokeObjectURL(link.href);
    }

    const openFileLink = (link: string) => {
        const newTab = window?.open(link, '_blank');
        newTab?.focus();
    }

    console.log('pdfUrls', rapbackPdfURL);

    const openDoc = async (e: any, row: any) => {
        addAuditEvent("Opened Adjudication Document", "Details / Adjudication Document", new Date(), row.registrationId)
        if (rapbackPdfURL.length > 0) {
            openFileLink(rapbackPdfURL[0])
        } else {
            openFileLink(row.fileLink)
        }
    }

    const actionsConfig: { actionIcon?: ReactNode, actionLabel: string, actionHandler: Function }[] = [
        {
            actionIcon: <PrintIcon sx={{ color: 'text.secondary', mr: '1rem' }} fontSize="small" />,
            actionLabel: "Print",
            actionHandler: printDoc
        },
        // {
        //   actionIcon: <SaveAltIcon sx={{ color: 'text.secondary', mr: '1rem'}} fontSize="small"/>,
        //   actionLabel: "Save as",
        //   actionHandler: saveDoc
        // },
        {
            actionIcon: <OpenInNewIcon sx={{ color: 'text.secondary', mr: '1rem' }} fontSize="small" />,
            actionLabel: "View",
            actionHandler: openDoc
        }
    ]

    const { tableHeaderCells, page, rowsPerPage } = useSetConfig(pageName, { page: 0, rowsPerPage: 5 }, TableNames.ADJUDICATION_DOCS, {}, actionsConfig)

    useEffect(() => {
        console.log('debug setPagination in applicantDetails: ', { setPagination, typeOfSetPaginaiton: typeof setPagination })
        setPagination({ ...pagination, [TableNames.ADJUDICATION_DOCS]: { page: 0, rowsPerPage: 5 } })
    }, [])

    return (
        <Container disableGutters maxWidth={false} sx={{ marginTop: "24px", marginBottom: "24px", width: "100%" }}>
            {
                tableHeaderCells &&
                <CustomTable
                    tableConfig={tableConfig}
                    tableName={name}
                    savedSearchState={[{}, () => { }]}
                    pageSize={rowsPerPage}
                    totalRecords={totalRecords}
                    currentPage={page}
                    hideSearch={true}
                    defaultSortColumn="date"
                    order="desc"
                    orderBy="addedDate"
                    rows={adjudicationDocuments}
                />
            }

        </Container>
    )
}