/*
 * IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA
 * GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT
 *
 * Copyright: 2023 by Idemia Identity & Security USA LLC. All rights reserved.
 * License: In accordance  Idemia I&S USA LLC's license agreement.
 * Code Classification: GOVERNMENT
 *
 * Classification Person: Nadim Bakizada nadim.bakizada@us.idemia.com
 * Classification Reason: Software not specific to any U.S. Government Entity
 * Classification Date: 2023
 *
 * GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT
 * IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA
 */

import { useState } from 'react';
import {
    Tabs,
    Tab,
    Grid,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Button,
    Typography,
    Divider,
    Box
} from '@mui/material';
import { ApplicantDetailsForm } from './ApplicantDetailsForm/ApplicantDetailsForm';
import { Notes } from 'components/Notes/Notes';
import Results from './Results/Results';
import { ApplicantDetailsActionTypes, useApplicantDetailsContext } from 'pages/ApplicantDetails/components/ApplicantDetailsContext/ApplicantDetailsProvider'
import { usePdfURL } from 'hooks/usePdfURL';
import { useMUITheme } from "theme/ThemeProvider";
import { formatDate } from "utils/formatDate";
import { ApplicantDetailsBodyProps } from 'interfaces/pages/index';
import { AuditHistoryTable } from 'common/AuditHistoryTable';
import { TableNames } from 'customEnums/TableNames';
import { DocumentUpload } from './DocumentUpload/DocumentUpload';
import { EmailDelivery } from './EmailDelivery/EmailDelivery';
import { CustomModal } from 'components/AppBar/components/CustomModal';
import { useCustomModalStore } from 'state/CustomModalStore';

const formatResults = (results: any, status: string, addedDate: string, fileName: string) => {
    console.log('formatResults results: ', results)
    console.log('formatResults status: ', status)
    console.log('formatResults addedDate: ', addedDate)
    return results?.map((result: any) => {
        return {
            key: result,
            decision: status ?? "N/A",
            addedBy: result.addedBy ?? "N/A",
            addedDate: addedDate ?? "N/A",
            fileNameControl: fileName ? fileName : 'Adjudication.pdf',
            fileLink: result,
            deleteControl: 'delete'
        }
    });
};

export const ApplicantDetailsBody: React.FC<ApplicantDetailsBodyProps> = ({
    userRole,
    personalInfo,
    transactionalInfo,
    applicantFetchStatus,
    statusHistories,
    registrationId,
    results,
    aggregatedResponses,
    rapSheets,
    resultsResponses,
    usersPermissions,
    responseAssociationData,
    requestingAgencyInfo,
    employmentInfo
}) => {

    console.log('debug transactionalInfo', transactionalInfo);
    console.log('debug results results: ', results)
    console.log('debug Aggregated responses: ', aggregatedResponses)

    const { applicantDetailsDispatch } = useApplicantDetailsContext();
    const { pdfURLs } = usePdfURL(registrationId as string);
    
    const data = formatResults(pdfURLs, transactionalInfo.currentStatus, transactionalInfo.lastModifiedBy, transactionalInfo.fileName);
    console.log('adjudication documents data: ', data)

    const { mode } = useMUITheme();
    const [currentUser, setCurrentUser] = useState<any>()

    const [value, setValue] = useState(0);
    const [editRedactedMode, setEditRedactedMode] = useState<boolean>(false);
    const [textChanges, setTextChanges] = useState<boolean>(false);
    const [open, setOpen] = useState<boolean>(false)
    const [moveTabs, setMoveTab] = useState(1)

    const dialogStyles = useCustomModalStore(state => state.dialogStyles)
    const openCustomModal = useCustomModalStore(state => state.openCustomModal)
    const customModalTitle = useCustomModalStore(state => state.customModalTitle)
    const contentText = useCustomModalStore(state => state.contentText)
    const contentStyle = useCustomModalStore(state => state.contentStyle)
    const modalActionsConfig = useCustomModalStore(state => state.modalActionsConfig)

    const mappedData = data?.map((value: any) => ({
        ...value,
        addedDate: formatDate(value.addedDate),
        registrationId: registrationId
    }))

    const handleCloseToSave = () => {
        setOpen(false) 
        setTextChanges(false)
        setEditRedactedMode(true)
    }

    const handleChange = (event?: any, newValue?: any, key?: any) => {
        if (editRedactedMode && textChanges) {
            setOpen(true)
            setMoveTab(newValue);
        } else {
            setEditRedactedMode(false);
            setValue(newValue);
        }
        applicantDetailsDispatch({ type: ApplicantDetailsActionTypes.UPDATE_TAB, payload: { currentTab: key } })
    };

    const handleCloseWithoutSaving = () => {
        setOpen(false)
        setTextChanges(false)
        setEditRedactedMode(false)
        setValue(moveTabs)
    }

    const renderTabs = (items) => {
        if (!usersPermissions?.['createNewNotes'] && !usersPermissions?.['viewOthersNotes']) {
            return items.slice(0, -2).map((item, index) => <Tab key={index} label={item.label} />);
        }
        if (!usersPermissions?.['viewAuditHistory']) {
            return items.slice(0, -1).map((item, index) => <Tab key={index} label={item.label} />);
        }
        else {
            return items.map((item, index) => <Tab key={index} label={item.label} />);
        }
    }


    let items = [
        {
            label: "DETAILS",
            key: 'DETAILS',
            children: statusHistories && (
                <ApplicantDetailsForm
                    usersPermissions={usersPermissions}
                    userRole={userRole}
                    personalInfo={personalInfo}
                    employmentInfo={employmentInfo}
                    transactionalInfo={transactionalInfo}
                    applicantFetchStatus={applicantFetchStatus}
                    adjudicationDocuments={mappedData}
                    requestingAgencyInfo={requestingAgencyInfo}
                    statusHistories={statusHistories} />
            )
        },
        {
            label: 'RESULTS',
            key: 'RESULTS',
            children:
                <>
                    <Dialog open={open} onClose={() => setOpen(false)}>
                        <DialogTitle sx={{ width: '37.5rem' }}>YOU HAVE UNSAVED CHANGES.</DialogTitle>
                        <DialogContent>
                            <DialogContentText>
                                <Typography>
                                    You are leaving the redacted document with unsaved changes. If you proceed, all changes that are not saved will be lost. Would you like to continue?
                                </Typography>
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions sx={{ pr: '1.5rem', pb: '1rem' }}>
                            <Button variant='outlined' color='primary' sx={{ color: mode === 'dark' ? '#fff' : null }} onClick={handleCloseToSave}>CANCEL AND RETURN</Button>
                            <Button variant='contained' color='primary' onClick={handleCloseWithoutSaving}>EXIT WITHOUT SAVING</Button>
                        </DialogActions>
                    </Dialog>
                    <Results
                        setEditRedactedMode={setEditRedactedMode}
                        editRedactedMode={editRedactedMode}
                        setTextChanges={setTextChanges}
                        open={open}
                        setOpen={setOpen}
                        setValue={setValue}
                        textChanges={textChanges}
                        tabValue={value}
                        userRole={userRole}
                        results={results}
                        aggregatedResponses={aggregatedResponses}
                        rapSheets={rapSheets}
                        transactionalInfo={transactionalInfo}
                        resultsResponses={resultsResponses}
                        responseAssociationData={responseAssociationData}
                    />
                </>
        },
        {
            label: 'NOTES',
            key: 'NOTES',
            children:
                <Notes
                    personalInfo={personalInfo}
                    userRole={userRole}
                    currentUser={currentUser}
                    usersPermissions={usersPermissions}
                />
        },
        ...(process.env.NODE_ENV !== 'production'
            ? [{
                label: 'DOCUMENT',
                key: 'DOCUMENT',
                children: <DocumentUpload registrationId={registrationId} />,
            }]
            : []),
        {
            label: 'HISTORY',
            key: 'HISTORY',
            children:
                <AuditHistoryTable
                    id={registrationId}
                    name={TableNames.AUDIT_HISTORY}
                />
        },
        ...(usersPermissions["viewSecureEmailTab"]
            ? [{
                label: 'SECURE EMAIL DELIVERY',
                key: 'EMAIL DELIVERY',
                children: 
                <>
                <CustomModal 
                    modalActionsConfig={modalActionsConfig}
                    dialogStyles={dialogStyles}
                    open={openCustomModal}
                    modalTitle={customModalTitle}
                    contentText={contentText}
                    contentStyle={contentStyle}
                />
                <EmailDelivery registrationId={registrationId} status={results[0]["status"]}/>
                </>
            }]
            : []),
    ];

    return (
        <Grid width='100%' padding='0' sx={{ backgroundColor: 'background.paper', overflowY: "scroll", borderRadius: 1 }}>
            <Grid item position='sticky' top={0} zIndex={3} >
                <Box sx={{
                    bgcolor: 'white',
                    padding: '0rem 1rem',
                    backgroundColor: 'common.black',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                }}
                >
                    <Tabs
                        value={value}
                        onChange={handleChange}
                        sx={{
                            bgcolor: 'white',
                            padding: '0.5rem 1rem',
                            backgroundColor: 'common.black',
                            display: 'flex',
                            alignItems: 'center',
                        }}
                    >
                        {renderTabs(items)}
                    </Tabs>
                    {
                        items[value].label === "DOCUMENT" &&
                        <Button variant="contained" sx={{ padding: "6px 16px", borderRadius: "6px" }}>
                            UPLOAD DOCUMENTS
                        </Button>
                    }
                </Box>
                <Divider style={{ background: 'rgba(255, 255, 255, 0.12)', width: '100%' }} />
            </Grid>
            {items[value].children}
        </Grid>
    );
};
