import React, { useEffect, useState } from "react"
import { useLocation } from "react-router-dom";
import { useTableStore, useCustomModalStore } from "../../../../../state";
import { TableNames } from "../../../../../customEnums/TableNames";
import { CustomTable } from "../../../../../common";
import { extractPageName } from "../../../../../common/CustomTable/helpers";
import { useSetConfig } from "../../../../../hooks";
import dayjs from "dayjs";

export const EmailDeliveryTable: React.FC<any> = ({ name, tableData, totalRecords, resendEmail }) => {
    const locationObj = useLocation()
    const pageName = extractPageName(locationObj.pathname)
    const tableConfig = useTableStore(state => state.tableConfig)
    const pagination = useTableStore(state => state.pagination)
    const setPagination = useTableStore(state => state.setPagination)
    const selected = useTableStore(state => state.selected)
    const setSelected = useTableStore(state => state.setSelected);
    const setOpenCustomModal = useCustomModalStore(state => state.setOpenCustomModal)
    const setCustomModalTitle = useCustomModalStore(state => state.setCustomModalTitle)
    const setContentText = useCustomModalStore(state => state.setContentText)
    const setContentStyle = useCustomModalStore(state => state.setContentStyle)
    const setModalActionsConfig = useCustomModalStore(state => state.setModalActionsConfig)
    
    const [data, setData] = useState<any>(tableData);
    const maxResendCount = tableConfig[TableNames.EMAIL_DELIVERY]?.maxResendCount;

    const resendEmailButton = async (row) => {
        const resp = await resendEmail(row.id, row.recipientEmail);
        console.log('debug response in resending email: ', resp);
        if (resp.ok) {
            setData((prevData: any) => {
                return prevData.map((data: any) => {
                  if (data.key === row.key) {
                    return {
                      ...data,
                      resubmitCount: data.resubmitCount + 1,
                      deliveryStatus: "Sent",
                      date: dayjs(new Date()).format("MM/DD/YYYY hh:mm:ss A")
                    }
                  }
                  return data
                })
              })
        }
    }

    const buttonCellHandlers = {
        resend: {
            clickHandler: (e, row) => {
                setOpenCustomModal(true);
            
                // Able to resend email
                if (dayjs().isBefore(row.expiredDate) && row.resubmitCount < maxResendCount) {
                    setContentText(
                        `You are about to resend this email to: ${row.recipientEmail} \n
                            • Resends Remaining: ${maxResendCount - row.resubmitCount}
                            • You have until ${dayjs(row.expiredDate).format("MMM D, YYYY hh:mm A")} before the option to resend expires
                    `)
                    setContentStyle({ display: "flex", flexDirection: "column", justifyContent: "space-evenly", })
                    setCustomModalTitle("RESEND EMAIL CONFIRMATION");

                    setModalActionsConfig([
                        {
                            onClick: () => setOpenCustomModal(false),
                            variant: "outlined",
                            buttonTitle: "Cancel"
                        },
                        {
                            onClick: () => resendEmailButton(row),
                            variant: "contained",
                            buttonTitle: "CONFIRM RESEND"
                        }

                    ])
                }
                // cannot resend due to reaching max resend count
                if (dayjs().isBefore(row.expiredDate) && row.resubmitCount >= maxResendCount) {
                    setCustomModalTitle("UNABLE TO RESEND EMAIL");
                    setContentText(`You have reached the amount of available resends for this e-mail.`);
                    setModalActionsConfig([
                        {
                            onClick: () => setOpenCustomModal(false),
                            variant: "contained",
                            buttonTitle: "CLOSE"
                        }
                    ])
                }
            }
        }
    }

    const { tableHeaderCells, page, rowsPerPage } = useSetConfig(pageName, { page: 0, rowsPerPage: 10 }, TableNames.EMAIL_DELIVERY, {}, {}, buttonCellHandlers)

    useEffect(() => {
        setPagination({ ...pagination, [TableNames.EMAIL_DELIVERY]: { page: 0, rowsPerPage: 10 } })
    }, [])


    return (
        <>
            {
                tableHeaderCells &&
                <CustomTable
                    tableConfig={tableConfig}
                    tableName={name}
                    pageSize={rowsPerPage}
                    totalRecords={totalRecords}
                    rows={data}
                    orderBy={"date"}
                    order={"desc"}
                    hideSearch={true}
                    currentPage={page}
                    savedSearchState={[{}, () => { }]}
                    selected={selected}
                    setSelected={setSelected}
                    rowClickHandler={(e, row) => { }}
                />
            }
        </>
    )
}