import { CustomTable } from 'common/CustomTable/CustomTable';
import {
  Paper,
  Drawer,
  Box,
  Divider,
  Typography,
  Button,
  Grid,
  Container,
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close';
import PrinterIcon from '@mui/icons-material/LocalPrintshop';
import { CustomTableTitleAndAlert, RightDrawer } from '../../common'
import { useExpanded } from 'components/layouts/ProtectedLayout';
import React, { useCallback, useState, useEffect, useMemo, useReducer } from 'react'
import { useQueryRapBack } from "hooks/useQueryRapBack"
import printText from "utils/printText";
import { useAudit } from "hooks/useAudit";
import { Auth } from 'aws-amplify';
import SimpleBar from 'simplebar-react';
import 'simplebar-react/dist/simplebar.min.css';
import { Order } from '../../customTypes/index';
import { RapbackSummaryStyles } from '../../customStyles/index';
import { pageStyles } from 'customStyles/common';
import { ColumnState, useTableStore } from 'state/TableStore';
import { extractPageName } from "../../common/CustomTable/helpers";
import { useLocation } from "react-router-dom";
import { formatDate } from 'utils';
import { AuditHistoryTable } from 'common/AuditHistoryTable';
import { useConfigService, useRapbackPdfURL, useSetConfig } from '../../hooks';
import { ReactNode } from 'react';
import { Document, Page } from 'react-pdf';
import { useUserStore } from 'state';
import { TableNames } from 'customEnums/TableNames';
import { savedSearchesReducer } from 'pages/TransactionSummary/helpers/savedSearchesReducer';
import { pdfjs } from 'react-pdf';

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;

export const RapBackSummary: React.FC = () => {
  const { expanded } = useExpanded();
  const [sortOrder, setSortOrder] = useState<Order>('desc');
  const [orderBy, setOrderBy] = useState<string>('insertDate');
  const [displaySSN, setDisplaySSN] = useState('')
  const [fullName, setFullName] = useState('');
  const [showSSN, setShowSSN] = useState<boolean>(false);
  const [details, setDetails] = useState<string>("")
  const [sidCurrent, setSid] = useState<string>("")
  const [openRapbackDrawer, setOpenRapbackDrawer] = useState<boolean>(false);
  const { addAuditEvent } = useAudit();
  const [searched, setSearched] = useState<boolean>(false);
  const [value, setValue] = React.useState<any>([]);
  console.log('debug userStore setting userConfig')
  const userConfig = useUserStore(state => state.userConfig)
  const { userRole, usersPermissions } = userConfig;
  const [currentKey, setCurrentKey] = useState("")
  const [data, setData] = useState<[]>([]);
  const [openAuditDrawer, setOpenAuditDrawer] = useState(false)
  const [id, setId] = useState("");
  const [applicantName, setApplicantName] = useState("")

  const searchParams = useTableStore((state) => state.searchParams)
  const pagination = useTableStore((state) => state.pagination)
  const setPagination = useTableStore(state => state.setPagination)
  const displayNoSearchResults = useTableStore((state) => state.displayNoSearchResults)
  const setDisplayNoSearchResults = useTableStore(state => state.setDisplayNoSearchResults)
  const openNotification = useTableStore(state => state.openNotification)
  const setOpenNotification = useTableStore(state => state.setOpenNotification)
  const simpleSearchRan = useTableStore(state => state.simpleSearchRan)
  const setActionsConfig = useTableStore(state => state.setActionsConfig)

  const { rapbackPdfURL } = useRapbackPdfURL(currentKey as string, openRapbackDrawer);
  const locationObj = useLocation();
  const pageName = extractPageName(locationObj.pathname);
  const tableConfig = useTableStore(state => state.tableConfig)
  const isPost = useTableStore((state) => state.isPost)
  const isDelete = useTableStore((state) => state.isDelete)
  const configFile = useTableStore((state) => state.configFile)
  const configBody = useTableStore((state) => state.configBody)
  const { searchData } = useConfigService(configFile, configBody, true, isPost, isDelete);
  const loadedData = useMemo(() => ({ ...searchData }), [searchData]);
  const setDefaultOrderBy = useTableStore(state => state.setDefaultOrderBy)
  const [url, setUrl] = useState<any>()
  const [numPages, setNumPages] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  console.log('debug viewRapBack RapbackSummary rerender')

  useEffect(() => {

    console.log('debug viewRapBack rapbackPdfURL useEffect running rapbackPdfURL: ', rapbackPdfURL)

    const getUlr = async () => {
      let pdfUrl = rapbackPdfURL[0]
      console.log('debug viewRapBack rapbackPdfURL pdfUrl: ', pdfUrl)
      const arrayBuffer = await fetch(pdfUrl);
      console.log('debug rapbackPdfURL arrayBuffer: ', arrayBuffer)
      const blob = await arrayBuffer.blob();
      console.log('debug rapbackPdfURL blob: ', blob)

      const url = await blobToURL(blob);
      console.log('debug rapbackPdfURL url: ', url)

      setUrl(url)
    }

    const blobToURL = (blob) => {
      return new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onloadend = function () {
          const base64data = reader.result;
          resolve(base64data);
        };
      });
    }

    if (rapbackPdfURL.length) getUlr()

  }, [rapbackPdfURL])

  const handleOpenAuditHistory = async (e, row) => {
    console.log('debug Rapback summary mappedData - Audit History row: ', row)
    setOpenAuditDrawer(true);
    setId(row.key)
    setApplicantName(`${row.firstName + " " + row.lastName}`)
  }

  const actionsConfig: { actionIcon?: ReactNode, actionLabel: string, actionHandler: Function }[] = [
    usersPermissions?.viewRapBackDetails && {
      actionLabel: "View Details",
      actionHandler: (e, row) => {
        e.stopPropagation()
        setIsLoading(true)
        viewRapBack(e, row)
      }
    },
    {
      actionLabel: "Record History / Notifications",
      actionHandler: handleOpenAuditHistory
    }
  ].filter(Boolean)

  setActionsConfig(actionsConfig)

  const { tableHeaderCells, page, rowsPerPage } = useSetConfig(pageName, { page: 1, rowsPerPage: 25 }, TableNames.RAPBACK_SUMMARY, {}, actionsConfig)

  const {
    data: mappedData,
    runCount,
    totalRecords,
    loading, upsertViewedRecord }: any = useQueryRapBack(rowsPerPage, page, sortOrder, orderBy, searchParams, userRole, TableNames.RAPBACK_SUMMARY)

  useEffect(() => {
    setDefaultOrderBy("insertDate")

    setPagination({ ...pagination, [TableNames.RAPBACK_SUMMARY]: { page: 1, rowsPerPage: 25 } })
  }, [])

  useEffect(() => {
    const { sortOrder } = pagination[TableNames.RAPBACK_SUMMARY]
    console.log('debug reset sort order: ', pagination)
    if (sortOrder) setSortOrder(sortOrder)
  }, [pagination[TableNames.RAPBACK_SUMMARY]])

  useEffect(() => {
    console.log('debug customTableHead rapback mappedData: ', mappedData)
    setData(mappedData);
  }, [mappedData?.length, mappedData])

  const handleClickShowSSN = useCallback(async (record: string, e: any) => {
    if (displaySSN !== record) {
      setDisplaySSN(record)
      setShowSSN(!showSSN)
    } else {
      setDisplaySSN('')
      setShowSSN(!showSSN)
    }
  }, [displaySSN])


  const viewRapBack = async (e, row: any) => {
    console.log('viewRapback text', row)
    let key = row.key || row.id
    const { username } = await Auth.currentAuthenticatedUser()
    console.log('debug viewRapBack rapBackSummary rapBack: ', { mappedData, key })
    const details = mappedData.find((obj: any) => obj.key === key).fileData
    const sidCurrent = mappedData.find((obj: any) => obj.key === key).sid
    const firstName = mappedData.find((obj: any) => obj.key === key).firstName
    const middleName = mappedData.find((obj: any) => obj.key === key).middleName
    const lastName = mappedData.find((obj: any) => obj.key === key).lastName

    // update record in the backend once rapback is viewed - should utilize userPermissions in the future
    if (usersPermissions?.triggerViewedRecord) {
      const resp = await upsertViewedRecord(key, username);
      if (resp.code === 200) {
        setData((prevData: any) => {
          return prevData.map((data: any) => {
            if (data.key === key) {
              return {
                ...data,
                viewed: true,
                viewedUser: username,
                viewedDate: new Date().toISOString()
              }
            }
            return data
          })
        })
      }
    }

    setFullName(`${firstName} ${middleName} ${lastName} `)
    setDetails(details)
    setSid(sidCurrent)
    console.log('click event rapback viewRapBack text: ', key)
    addAuditEvent("Viewed", `Viewed Rapback Details`, new Date(), key)
    setOpenRapbackDrawer(true)
    setCurrentKey(key)
  }

  const closeDrawer = () => {
    console.log('debug multi table pagination insideCloseDrawer')
    setOpenRapbackDrawer(false)
    setOpenAuditDrawer(false)
    setCurrentKey("")
    setUrl("")
    setIsLoading(true)
    setPagination({
      ...pagination,
      [TableNames.RB_SUMMARY_AUDIT_HISTORY]: {
        page: 0,
        rowsPerPage: 5,
        sortOrder: "desc",
        orderBy: ""
      }
    })
  }

  const createMarkup = (details: string) => {
    console.log('rapBackSummary createMarkup rapBacks: ', mappedData)
    if (mappedData)
      return { __html: details || mappedData.fileData };
  }

  const printRapbackDetails = () => {
    printText(details ?? "", "", "", {
      printBackground: true,
    })
    addAuditEvent("Printed Report ", "Print data for SID: " + sidCurrent, new Date(), currentKey)
  };

  let printData: any
  const printRapsheetPDF = () => {
    //print()
    if (printData !== undefined) {
      const blob = new Blob([printData], { type: 'application/pdf' });
      window.open(URL.createObjectURL(blob))
      addAuditEvent("Printed Report ", "Print data for SID: " + sidCurrent, new Date(), currentKey)
    }
  }

  const handleResponseType = (type: string) => {
    if (type.toLowerCase() === 'n') {
      return 'No Hit'
    }
    if (type.toLowerCase() === 'y') {
      return 'Hit'
    }
    if (type.toLowerCase() === 'errt') {
      return 'ERRT-Error'
    }
    if (type.toLowerCase() === "n/a") {
      return "N/A"
    }
  }

  const [screenHeight, setScreenHeight] = useState(window.innerHeight);

  const handleRequestSort = (event: any, property) => {
    if (property === "reasonFingerPrinted") {
      property = "rfp"
    } else if (property === "oris") {
      property = "ori"
    }
    console.log(`sort date handleReuqestSort sortOrder: ${sortOrder} orderBy ${orderBy} property: ${property}`)
    const isAsc = orderBy === property && sortOrder === 'asc';
    console.log(`sort date handleRequestSort sortOrder: isAsc ${isAsc}`)
    setSortOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property);
  };

  useEffect(() => {
    console.log('debug stuck search results useEffect: ', { runCount, searchParams, mappedData, simpleSearchRan, searched, setDisplayNoSearchResults: mappedData?.length === 0 && simpleSearchRan || mappedData?.length === 0 && searched })
    console.log('debug stuck search results useEffect: ', { conditional: runCount < 1 && searchParams.length > 0, negativeConditional: !(runCount < 1 && searchParams.length > 0) })
    if (runCount < 1 && searchParams.length > 0) {
      setDisplayNoSearchResults(false)
      setOpenNotification(false)
      return
    }
    if (mappedData?.length === 0 && simpleSearchRan || mappedData?.length === 0 && searched) {
      setDisplayNoSearchResults(true)
      setOpenNotification(true)
    }
    //console.log('debug open notification display no seasrch', displayNoSearchResults)
    console.log('debug open notification', openNotification)

  }, [mappedData?.length, simpleSearchRan, searched])

  useEffect(() => {
    if (displayNoSearchResults) {
      setTimeout(() => {
        setDisplayNoSearchResults(false)
        setOpenNotification(false)
      }, 10000)
    }
  }, [displayNoSearchResults])

  useEffect(() => {
    //setOpenAuditHistory(handleOpenAuditHistory)

    // useEffect for adapting to different screen size
    const handleResize = () => {
      setScreenHeight(window.innerHeight);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [mappedData])



  const onDocumentLoadSuccess = async (succcessEvent) => {
    setNumPages(succcessEvent.numPages);
    const data = await succcessEvent.getData()
    if (data) {
      printData = data
      //setLoadingPDF(false)
      setIsLoading(false)
    }
    console.log('successEvent: ', { numPages, data })
  }

  const onDocumentLoadError = async (errorEvent) => {
    console.log('debug viewRapBack RenderPdf Document failed to load: ', errorEvent.message)
    setIsLoading(false)
  }

  const RenderPdf = ({ renderMode, file }) => {

    console.log('debug viewRapBack RenderPdf: ', { isLoading, file, numPages, currentKey, openRapbackDrawer, sidCurrent, viewRapSheetPdf: usersPermissions?.viewRapsheetPDF, url })
    return (

      <>

        {isLoading && <Container sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "800px", width: "100%" }}>
          <Typography sx={{ animation: "pulsate 3s ease-out" }} color="primary" fontSize="2rem">Loading...</Typography>
        </Container>}

        <Document loading=""
          onLoadError={onDocumentLoadError}
          onLoadSuccess={onDocumentLoadSuccess} file={file}>

          {Array.from(
            new Array(numPages),
            (el, index) => (
              <Page
                loading=""
                width={824}
                key={`page_${index + 1}`}
                pageNumber={index + 1}
              />
            ),
          )}
        </Document>
      </>

    )
  }

  return (
    <>
      {
        usersPermissions?.viewRapBack && (
          <Paper sx={{ ...pageStyles.pagePaperStyle, minWidth: `calc(100vw - ${expanded ? "256px" : "64px"})` }} elevation={0} >
            <CustomTableTitleAndAlert name="Rapback Summary" />
            <RightDrawer containsTable={true} openDrawer={openAuditDrawer} closeDrawer={closeDrawer}>
              <Container sx={{ marginBottom: "18px", marginTop: "20px" }} disableGutters>
                <Typography sx={{ fontSize: '1.5rem' }}>{applicantName} Audit History</Typography>
              </Container>
              <AuditHistoryTable id={id} name={TableNames.RB_SUMMARY_AUDIT_HISTORY} />
            </RightDrawer>
            <Drawer anchor='right' open={openRapbackDrawer} onClose={closeDrawer} sx={{ zIndex: 100000 }}>
              <Box sx={RapbackSummaryStyles.rapbackDrawerBox}>
                <Grid container justifyContent='flex-end' alignItems='center' sx={{ pb: '2rem', position: 'sticky', top: 0 }}>
                  <Button size='small' startIcon={<CloseIcon />} sx={{ color: 'text.primary' }} onClick={closeDrawer}>CLOSE</Button>
                </Grid>
                <Grid container display='flex' flexDirection='column' justifyContent='center' flexGrow={1}>
                  <Typography sx={{ fontSize: '1.5rem' }}>{fullName} - RAPBACK DETAILS</Typography>
                  <Divider sx={{ mt: '1.5rem', mb: '1.5rem' }} />
                  <SimpleBar style={{ flex: 1, overflowY: 'auto', height: screenHeight < 1000 ? '75vh' : '80vh' }}>
                    {

                      usersPermissions?.viewRapsheetPDF ? url && <RenderPdf renderMode="canvas" file={url} />

                        : <div dangerouslySetInnerHTML={createMarkup(details)} style={{ fontFamily: 'Montserrat', whiteSpace: "pre-line" }} />


                    }
                  </SimpleBar>
                  <Grid xs={12} item={true} sx={{ display: 'flex', mb: '1.5rem', mt: '1.5rem', justifyContent: 'flex-end' }} bottom={0} position='fixed' width='820px'>
                    <Button
                      sx={{ borderRadius: '0.375rem' }}
                      color='secondary'
                      variant='contained'
                      startIcon={<PrinterIcon />}
                      disabled={usersPermissions?.printRapsheetPDF ? rapbackPdfURL.length > 0 ? false : !usersPermissions?.printRapsheetPDF ? false : true : false}
                      onClick={(rapbackPdfURL.length > 0 && usersPermissions?.printRapsheetPDF) ? printRapsheetPDF : printRapbackDetails}
                    >
                      PRINT
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            </Drawer>
            {
              tableHeaderCells && (
                <CustomTable
                  tableConfig={tableConfig}
                  tableName="rapback-summary"
                  loading={loading}
                  rows={data?.length ? data : []}
                  orderBy={orderBy}
                  order={sortOrder}
                  totalRecords={totalRecords}
                  currentPage={page}
                  pageSize={rowsPerPage}
                  searched={searched}
                  value={value}
                  handleRequestSort={handleRequestSort}
                  setValue={setValue}
                  setSearched={setSearched}
                  defaultSortColumn={orderBy}
                  savedSearchState={[{}, () => { }]}
                  handleClickShowSSN={handleClickShowSSN}
                  displaySSN={displaySSN}
                  rowClickHandler={viewRapBack}
                  openModalBlur={openRapbackDrawer}
                />
              )
            }

          </Paper>
        )

      }
    </>
  )
}
